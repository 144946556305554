import React from "react"
import styles from "../css/footer.module.css"
import Iframe from "react-iframe"
import { FormattedMessage } from "gatsby-plugin-intl"

const Footer = () => {
  return (
    <section id="footer" className="w-75-ns center mv6 ph3">
      <div className="flex flex-row flex-wrap justify-between center">
        <div className="flex-column w-30-l center">
          <p className={styles.titre}>
            <FormattedMessage id="layout.footer.adress.title" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.adress.street" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.adress.zone" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.adress.ville" />
          </p>

          <i className={styles.tiret}>-</i>

          <p className={styles.titre}>
            <FormattedMessage id="layout.footer.horraires.title" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.horraires.l1" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.horraires.l2" />
          </p>

          <i className={styles.tiret}>-</i>

          <p className={styles.titre}>
            <FormattedMessage id="layout.footer.contact.title" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="layout.footer.contact.phone" />
          </p>
          <p className={styles.contenu}>
            cesar.le<oups style={{ display: "none" }}>SUPP@RIMER</oups>
            gris@fany-glass.fr
          </p>

          <div className="mt4">
            <a href="https://www.facebook.com/vitrail95/">
              <i
                className="fab fa-facebook-square fa-2x w-25"
                aria-label="Facebook"
              ></i>
            </a>
            <a
              href="https://www.instagram.com/fany_glass/"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram fa-2x w-25"></i>
            </a>
            <a
              href="https://www.pinterest.fr/cesarlegris0296"
              aria-label="Pinterest"
            >
              <i className="fab fa-pinterest fa-2x"></i>
            </a>
          </div>
        </div>

        <div
          className={(styles.iframe += " mt5 mt0-l pl2-l w-100 w-70-l center")}
        >
          <Iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5228.582346503226!2d2.1065744311828682!3d49.06209971955638!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb5592fad7f1a924!2sAtelier%20Vitrail%20Fany%20Glass!5e0!3m2!1sfr!2sfr!4v1580201456002!5m2!1sfr!2sfr"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
          />
        </div>
      </div>
    </section>
  )
}
export default Footer
