import React from "react"
import styles from "../css/overlay.module.css"
import {
  FormattedMessage,
  Link,
  IntlContextConsumer,
  changeLocale,
} from "gatsby-plugin-intl"

const languageName = {
  fr: "FR",
  en: "EN",
}

const Overlay = () => {
  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%"
  }
  return (
    <div id="myNav" className={styles.overlay}>
      <a
        className={styles.closebtn}
        style={{ cursor: "pointer" }}
        onClick={closeNav}
      >
        &times;
      </a>
      <div className={styles.overlayContent}>
        <Link className={styles.link} to="/portes-entrees/">
          <FormattedMessage id="layout.hamburger.front-doors" />
        </Link>
        <Link className={styles.link} to="/fenetres/">
          <FormattedMessage id="layout.hamburger.windows" />
        </Link>
        <Link className={styles.link} to="/verandas/">
          <FormattedMessage id="layout.hamburger.verandas" />
        </Link>
        <Link className={styles.link} to="/galerie-decors-fany-glass/">
          <FormattedMessage id="layout.hamburger.gallery" />
        </Link>
        <Link className={styles.link} to="/professionnel/">
          <FormattedMessage id="layout.hamburger.contact" />
        </Link>
        <span className="mh2">
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map(language => (
                <a
                  key={language}
                  onClick={() => changeLocale(language)}
                  style={{
                    color: `#818181`,
                    margin: 10,
                    textDecoration:
                      currentLocale === language ? `underline` : `none`,
                    cursor: `pointer`,
                  }}
                >
                  {languageName[language]}
                </a>
              ))
            }
          </IntlContextConsumer>
        </span>
      </div>
    </div>
  )
}

export default Overlay
