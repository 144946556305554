import React from "react"
import styles from "../css/copyrights.module.css"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Copyrights = () => {
  const data = useStaticQuery(graphql`
    query {
      logoBlanc: file(name: { eq: "logo-blanc" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <section id="copyrights" className={(styles.background += " pt5 pb3 ph3")}>
      <div className="flex flex-wrap-reverse justify-center justify-between-ns items-end w-75-ns center">
        <p className={(styles.centrer += " underline tc")}>
          <FormattedMessage id="layout.copyrights.copy" />
        </p>
        <Img
          className={styles.centrer}
          fixed={data.logoBlanc.childImageSharp.fixed}
        />
      </div>
    </section>
  )
}

export default Copyrights
