import React from "react"
import styles from "../css/contacter.module.css"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Contacter = () => (
  <section id="contacter" className={(styles.background += " pv4 mv6 ph3")}>
    <div className="w-75-ns center">
      <p className={(styles.titre += " tc")}>
        <FormattedMessage id="layout.contact.title" />
      </p>
      <p className={(styles.statut += " tc")}>
        <Link to="/professionnel">
          <FormattedMessage id="layout.contact.pro" />
        </Link>
      </p>
      <p className={(styles.statut += " tc")}>
        <Link to="/particulier">
          <FormattedMessage id="layout.contact.private" />
        </Link>
      </p>
    </div>
  </section>
)

export default Contacter
