import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import styles from "../css/header.module.css"
import Img from "gatsby-image"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  fr: "FR",
  en: "EN",
}

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 370) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (typeof window !== "undefined") {
    window.onscroll = function() {
      scrollFunction()
    }

    function scrollFunction() {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        if (window.matchMedia("(min-width: 60em)").matches) {
          document.getElementById("navbar").style.height = "110px"
          /* document.getElementById("logo").style.width = "300px" */
          document.getElementById("small-logo").style.width = "420px"
        } else {
          document.getElementById("navbar").style.height = "60px"
        }
      } else {
        if (window.matchMedia("(min-width: 60em)").matches) {
          document.getElementById("navbar").style.height = "150px"
          /* document.getElementById("logo").style.width = "370px" */
          document.getElementById("small-logo").style.width = "38%"
        } else {
          document.getElementById("navbar").style.height = "70px"
        }
      }
    }
  }

  const openNav = () => {
    document.getElementById("myNav").style.width = "100%"
  }

  return (
    <header
      id="navbar"
      className={(styles.navbar += " flex justify-between items-center")}
    >
      <div id="menu" className={(styles.hamb += " w-20 hamb pl3")}>
        <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={openNav}>
          &#9776;
        </span>
      </div>
      <div
        id="small-logo"
        className={(styles.logo += " center flex-column")}
        style={{ width: "38%" }}
      >
        <Link id="logo" className={styles.logo} to="/">
          <Img
            fluid={data.logo.childImageSharp.fluid}
            style={{ maxWidth: "370px", margin: "auto" }}
            alt="Vitrail Art Deco"
          />
        </Link>
        <nav className={(styles.nav += " flex flex-row justify-between")}>
          <Link to="/portes-entrees/" className={styles.link}>
            <FormattedMessage id="layout.header.front-doors" />
          </Link>
          <Link to="/fenetres/" className={styles.link}>
            <FormattedMessage id="layout.header.windows" />
          </Link>
          <Link to="/verandas/" className={styles.link}>
            <FormattedMessage id="layout.header.verandas" />
          </Link>
          <Link className={styles.link} to="/galerie-decors-fany-glass/">
            <FormattedMessage id="layout.hamburger.gallery" />
          </Link>
          <Link to="/particulier/" className={styles.link}>
            <FormattedMessage id="layout.header.contact" />
          </Link>
        </nav>
      </div>

      <div
        id="search"
        className={
          (styles.bye += " w-20 flex flex-row-reverse items-center pr3")
        }
      >
        <span className="mh2">
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map(language => (
                <a
                  key={language}
                  onClick={() => changeLocale(language)}
                  style={{
                    color: `black`,
                    margin: 10,
                    textDecoration:
                      currentLocale === language ? `underline` : `none`,
                    cursor: `pointer`,
                  }}
                >
                  {languageName[language]}
                </a>
              ))
            }
          </IntlContextConsumer>
        </span>
      </div>
    </header>
  )
}

export default Header
